var sectionVideo = document.querySelectorAll('.block_hero');

if (sectionVideo) {
    sectionVideo.forEach(function (el) {

        var videoHolder = el.querySelector('.video-holder');
        var video = el.querySelector('.video-holder video');
        if (video) {
            var videoSource = video.getElementsByTagName('source');
            var closeButton = videoHolder.querySelector('.close-video');
        }
        var playButtons = el.querySelectorAll('.block_hero .button-play');

        playButtons.forEach(function (el) {
            el.addEventListener('click', playVideo);
        });

        if (video) {
            closeButton.addEventListener('click', stopVideo);
        }

        function playVideo() {
            const videoWebm = this.getAttribute('data-video-webm');
            const videoMp4 = this.getAttribute('data-video-mp4');

            videoSource[0].src = videoWebm;
            videoSource[1].src = videoMp4;
            videoHolder.style.display = 'block';
            el.classList.add('video-play')

            video.load();
            video.play();
        }

        function stopVideo() {
            videoHolder.style.display = 'none';
            el.classList.remove('video-play')
            video.pause();
        }
    });
}
